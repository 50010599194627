/**
 * Use it to create simple modal controllers
 */

const DEFAULT_CLOSE_TIMEOUT = 3000;

export function useModal() {
  let closeTimer: ReturnType<typeof setTimeout> | null = null;
  const isOpen = ref(false);

  const open = (
    options = {
      closeTimeout: DEFAULT_CLOSE_TIMEOUT,
    },
  ) => {
    isOpen.value = true;

    if (options.closeTimeout) {
      if (closeTimer) {
        clearTimer();
      }

      closeTimer = setTimeout(() => {
        close();
      }, options.closeTimeout);
    }
  };

  const close = () => {
    isOpen.value = false;
  };

  const clearTimer = () => {
    if (closeTimer) {
      window.clearTimeout(closeTimer);
    }
  };

  return {
    isOpen: computed(() => isOpen.value),
    open,
    close,
    clearTimer,
  };
}

export const useSideMenuModal = createSharedComposable(useModal);
